import { graphql } from 'gatsby';
import React from 'react'
import PageSpace from '../components/PageSpace'
import PageHeader from '../components/PageHeader'
import SEO from '../components/SEOFile'
import BlogGrid from '../components/blog/BlogGrid';
import Pagination from '../components/Pagination';
import AdSense from 'react-adsense';

export const BlogsQuery = graphql`
    query blogListQuery($limit: Int!, $offset: Int!){
        allSanityBlog(
            sort: {fields: publishedAt, order: DESC}
            limit: $limit
            skip: $offset
        ){
            nodes{
                id
                title
                publishedAt
                slug{
                    current
                }
                categories{
                    title
                    slug{
                        current
                    }
                    
                }
                coverImage{
                        alt
                        asset{
                            gatsbyImageData
                        }
                    }
            }
        }
    }
`;

function BlogList({data, pageContext}) {
    const {currentPage, numberOfPages} = pageContext;
    const blogs = data ? data.allSanityBlog.nodes : []

    return (
        <>
          <SEO title="Auctux-Blogs"/>  
          <PageSpace>
            <div className='container'>
                <PageHeader 
                    title="All blog posts" 
                    description="this month will bring about the 88th Academy award, Starting in 1928, this prestigious award ceremony..."
                />
                <BlogGrid blogs={blogs}/>
                {
                    numberOfPages > 1 && (
                        <Pagination currentPage={currentPage} numberOfPages={numberOfPages} baseURL='/blogs'></Pagination>
                    )
                }
            </div>
            <AdSense.Google
            client='ca-pub-7363395996736530'
            slot='7806394673'
            style={{ display: 'block' }}
            format='auto'
            responsive='true'
            layoutKey='-gw-1+2a-9x+5c'
            />
          </PageSpace>
        </>
    )
}

export default BlogList
